<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col class="loader">
        <v-progress-circular
          v-if="loading"
          :size="100"
          :width="10"
          indeterminate
          color="primary"
        >
        </v-progress-circular>

        <v-alert v-if="integrationAlreadyPresent" dense text type="info">
          Integration updated. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="integrationSuccessful" dense text type="success">
          Integration successful. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="accessDeniedError" dense text type="error">
          Access denied by user. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="error" dense text type="error">
          <span v-html="searchAdsErrors"></span> Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="unknownError" dense text type="error">
          Unable to add integration, please try again. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { IntegrationTypes } from '@shared/enums';
import * as _ from 'lodash';
import { PROCESS_AUTH } from 'src/utils/apollo-mutations';
import Vue from 'vue';
import { updateCLF } from '../utils/util';
import { LSS } from '../core/services';
import { CheckListFields } from '@shared/enums/shared.enums';
import {CHECK_OLDEST_CAMPAIGN_DATE, GET_OAUTH_STATE_INFO} from 'src/utils/apollo-queries';
export default Vue.extend({
  name: 'SAIntegration',
  data() {
    return {
      countdown: 5,
      query: null,
      loading: false,
      accessDeniedError: false,
      integrationSuccessful: false,
      integrationAlreadyPresent: false,
      error: false,
      unknownError: false,
      searchAdsErrors: ''
    };
  },
  methods: {
    countDownTimer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async initAuth() {
      this.query = this.$route.query;

      if (_.isEmpty(this.query)) return this.$router.push({ name: 'add-integration' });

      if (this.query.error === 'access_denied') {
        this.accessDeniedError = true;
        this._checkStateAndRedirect({ accessDenied: true });
      } else {
        await this.processAuth();
      }
    },
    async _checkStateAndRedirect(query) {
      const stateInformation = await this._getStateInformation();

      this.redirect({ name: stateInformation?.referrer || 'add-integration', query });
    },
    async _getStateInformation() {
      try {
        const result = await this.$apollo.query({
          query: GET_OAUTH_STATE_INFO,
          variables: {
            state: this.query.state
          }
        });

        return result.data?.getOAuthStateInfo;
      }
      catch (error) {
        console.log(error)
      }
    },
    async checkOldestCampaignDate(IntegrationId){
      try {
        const result = await this.$apollo.query({
          query: CHECK_OLDEST_CAMPAIGN_DATE,
          variables: {
            integrationId:IntegrationId
          }
        });
        if(result.data?.checkOldestCampaignDate?.successMessage == "true"){
          LSS.ctoFlag = true
        }
        else{
          LSS.ctoFlag = false
        }
      }
      catch(e){
        console.log(e)
        LSS.ctoFlag = false
      }
    },
    async processAuth() {
      this.loading = true;

      const route = {}

      try {
        const result = await this.$apollo.mutate({
          mutation: PROCESS_AUTH,
          variables: {
            authReq: {
              code: this.query.code,
              state: this.query.state,
              type: IntegrationTypes.APPLE_SEARCH_ADS
            },
          },
        });

        route.name = result.data.processSAAuth.content?.oauthState?.referrer || 'add-integration';

        if(result.data.processSAAuth.content && result.data.processSAAuth.content.integrationAlreadyPresent) {
          this.integrationAlreadyPresent = true;
          route.query = { integrationUpdated: true, id: result.data.processSAAuth.data };
        } else if (result.data.processSAAuth.successMessage) {
          this.integrationSuccessful = true;
          updateCLF(CheckListFields['saIntegration'], this)
          if(result.data.processSAAuth.data) this.checkOldestCampaignDate(result.data.processSAAuth.data)
          route.query = { integrated: true, id: result.data.processSAAuth.data };
        } else if(result.data.processSAAuth.searchAdsErrors){
          this.searchAdsErrors = result.data.processSAAuth.searchAdsErrors;
          this.error = true;
          route.query = { asaError: true };
        } else {
          this.unknownError = true;
          route.query = { unknownError: true };
        }
      } catch (error) {
        this.unknownError = true;
        route.query = { unknownError: true };
      }

      this.redirect(route)
      this.loading = false;
    },

    redirect(route) {
      this.countDownTimer();
      setTimeout(() => {
        this.$router.push(route);
      }, 5000);
    },
  },
  created() {
    this.initAuth();
  },
});
</script>
